<div id="pills-groups" role="tabpanel" aria-labelledby="pills-groups-tab">
  <!-- Start Groups content -->
  <div>
    <div class="p-4">
      <div class="user-chat-nav float-end">
        <div ngbTooltip="{{ 'groups.modal.add' | translate}}">
          <!-- Button trigger modal -->
          <button type="button" class="btn btn-link text-decoration-none text-muted font-size-18 py-0" data-toggle="modal" data-target="#addgroup-exampleModal" (click)="create(content)">
            <i class="ri-add-circle-fill me-1 ms-0"></i>
          </button>
        </div>
      </div>
      <h4 class="mb-4">{{'groups.title' | translate}}</h4>

      <!-- Start add group Modal -->
      <ng-template #content let-modal>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
              {{'groups.modal.add' | translate}}</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            </button>
          </div>
          <div class="modal-body p-4">
            <form [formGroup]="formData">
              <div class="mb-4">
                <label class="form-label" for="addgroupname-input">{{'groups.modal.name.label' |
                  translate}}</label>
                <input type="text" formControlName="name" class="form-control" id="addgroupname-input" placeholder="{{'groups.modal.name.label' | translate}}">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal" (click)="modal.dismiss('Cross click')">{{'groups.button.cancel' |
              translate}}</button>
            <button type="button" class="btn btn-primary" (click)="createGroup()">{{'groups.button.save' |
              translate}}</button>
          </div>
        </div>
      </ng-template>

      <ng-template #editGroup let-modal>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-size-16" >
              {{'groups.modal.add' | translate}}</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            </button>
          </div>
          <div class="modal-body p-4">
            <form [formGroup]="formData">
              <div class="mb-4">
                <label class="form-label" for="addgroupname-input">{{'groups.modal.name.label' |
                  translate}}</label>
                <input type="text" formControlName="name" class="form-control"  placeholder="{{'groups.modal.name.label' | translate}}">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal" (click)="modal.dismiss('Cross click')">{{'groups.button.cancel' |
              translate}}</button>
            <button type="button" class="btn btn-link" data-dismiss="modal" (click)="deleteGroup(group)">{{'groups.button.delete' |
              translate}}</button>
            <button type="button" class="btn btn-primary" (click)="edit()">{{'groups.button.save' |
              translate}}</button>
          </div>
        </div>
      </ng-template>
      <!-- End add group Modal -->

      <div class="search-box chat-search-box">
        <div class="input-group rounded-3">
          <button class="input-group-text text-muted bg-light pe-1 ps-3" type="button">
            <i class="ri-search-line search-icon font-size-18"></i>
          </button>
          <input type="text" class="form-control bg-light" placeholder="{{'groups.search.placeholder' | translate}}" [(ngModel)]="searchQuery" id="searchGroup" autocomplete="off">
        </div>
      </div>
      <!-- end search-box -->
    </div>

    <!-- Start chat-group-list -->
    <ngx-simplebar class="p-4 chat-message-list chat-group-list" data-simplebar>
      <ul class="list-unstyled chat-list">
        @for(item of groups | searchGroupPipe: searchQuery ;track $index){
          <li (click)="getGroup(item, editGroup)">
            <a href="javascript:void(0);">
              <div class="d-flex align-items-center">
                <div class="chat-user-img me-3 ms-0">
                  <div class="avatar-xs">
                        <span class="avatar-title rounded-circle bg-primary-subtle text-primary">
                          {{(item.name | translate).charAt(0)}}
                        </span>
                  </div>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="text-truncate font-size-14 mb-0">{{item.name | translate}}
                  </h5>
                </div>
              </div>
            </a>
          </li>
        }
      </ul>
    </ngx-simplebar>
    <!-- End chat-group-list -->
  </div>
  <!-- End Groups content -->
</div>
