import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from "../../../environments/environment.prod";
import {StatusMessageModel} from "../models/status.message.model";
import {FormModel} from "../models/form.model";
import {ErrorService} from "./error.service";
import {catchError} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class FormService {
  constructor(private http: HttpClient,
              private errorService: ErrorService) {
  }

  getForms() {
    return this.http.get<FormModel[]>(`${environment.baseUrl}${environment.getForm}`)
      .pipe(catchError(this.errorService.handleError));
  }

  create(body: FormModel) {
    return this.http.post<StatusMessageModel>(`${environment.baseUrl}${environment.saveForm}`, body)
      .pipe(catchError(this.errorService.handleError));
  }

  edit(body: FormModel) {
    return this.http.patch<StatusMessageModel>(`${environment.baseUrl}${environment.saveForm}`, body)
      .pipe(catchError(this.errorService.handleError));
  }

  delete(formId: number) {
    return this.http.delete<StatusMessageModel>(`${environment.baseUrl}${environment.saveForm}/${formId}`)
      .pipe(catchError(this.errorService.handleError));
  }


}
