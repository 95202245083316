import {Pipe, PipeTransform} from '@angular/core';
import '@angular/common/locales/global/pl';
import {GroupModel} from "../models/group.model";

@Pipe({
  name: 'searchGroupPipe'
})
export class SearchGroupPipe implements PipeTransform {
  transform(groups:GroupModel[], date: string) {
    return groups.filter(g => g.name.includes(date));
  }
}
