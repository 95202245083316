import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from "../../../environments/environment.prod";
import {Conversations} from "../models/conversation.model";
import {catchError} from "rxjs/operators";
import {ErrorService} from "./error.service";

@Injectable({providedIn: 'root'})
export class ConversationsService {
  constructor(private http: HttpClient,
              private errorService: ErrorService) {
  }

  getConversations(groupId: number) {
    return this.http.get<Conversations[]>(`${environment.baseUrl}${environment.conversations}?groupId=${groupId}`)
      .pipe(catchError(this.errorService.handleError));
  }

}
