import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbAccordionModule, NgbModalModule, NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SimplebarAngularModule } from 'simplebar-angular';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { ContactsComponent } from './contacts/contacts.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [ContactsComponent],
    imports: [
        CarouselModule,
        CommonModule,
        NgbDropdownModule,
        NgbAccordionModule,
        SimplebarAngularModule,
        NgbTooltipModule,
        NgbModalModule,
        NgbCollapseModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule
    ],
  exports: [ContactsComponent],
  providers: [

  ]
})
export class TabsModule { }
