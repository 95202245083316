import {Component, OnInit, Output, EventEmitter} from '@angular/core';

import {Conversations} from "../../core/models/conversation.model";
import {AppContext} from "../../core/services/app.context";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GroupModel} from "../../core/models/group.model";
import {GroupService} from "../../core/services/group.service";
import {MessageService} from "../../core/services/message.service";
import {AddGroupsMessageModel} from "../../core/models/add.groups.message.model";
import {NgxSpinnerService} from "ngx-spinner";
import {NotifierService} from "angular-notifier-3";
import {AuthService} from "../../core/services/auth.service";

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})

/**
 * Chat-component
 */
export class ConversationComponent implements OnInit {
  conversations: Conversations[];
  @Output() conversation = new EventEmitter<Conversations>();
  searchQuery = '';
  selectedGroup: GroupModel;
  formData!: FormGroup;
  groups: GroupModel[];
  userId: number;

  constructor(public appContext: AppContext,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private notifierService: NotifierService,
              private messageService: MessageService,
              private authService: AuthService,
              private modalService: NgbModal,
              private groupService: GroupService) {
    this.userId = this.authService.currentUserValue.id;
    this.appContext.conversations.subscribe(next => {
      this.conversations = next;
      this.refreshList();
    })
    this.getGroups();
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });
  }
  refreshList(){

    this.conversations = this.conversations.sort((a, b) => {
      if (!a || !a.lastMessage) return 1;
      if (!b || !b.lastMessage) return -1;

      return new Date(b.lastMessage.createdAt).getTime() - new Date(a.lastMessage.createdAt).getTime();
    });

  }

  getGroups() {
    this.groupService.getGroups().subscribe(data => {
      this.groups = data;
      if (localStorage.getItem("selectedGroup") == "undefined") {
        localStorage.removeItem("selectedGroup");
      }
      const group = JSON.parse(localStorage.getItem("selectedGroup"));
      if (group) {
        this.selectedGroup = this.groups.find(g => g.id == group.id)
      } else {
        this.selectedGroup = this.groups.find(g => g.isDefault)
      }
      this.setGroup();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  setGroup() {
    localStorage.setItem("selectedGroup", JSON.stringify(this.selectedGroup));
    this.appContext.setGroup(this.selectedGroup);
  }

  ngOnInit(): void {
  }

  openModal(content) {
    this.modalService.open(content, {centered: true});
  }

  showChat(event: any, conv: Conversations) {
    var removeClass = document.querySelectorAll('.chat-user-list li');
    removeClass.forEach((element: any) => {
      element.classList.remove('active');
    });

    document.querySelector('.user-chat').classList.add('user-chat-show')
    document.querySelector('.chat-welcome-section').classList.add('d-none');
    document.querySelector('.user-chat').classList.remove('d-none');
    event.target.closest('li').classList.add('active');
    this.conversation.emit(conv);
  }

  send() {
    const groupsId = this.groups.filter(g => g.checked).map(g => g.id);
    const body: AddGroupsMessageModel = {
      content: this.formData.get('message')!.value,
      groupsId: groupsId
    }
    this.spinner.show();
    this.messageService.addMultiMessage(body).subscribe(data => {
      this.groups.filter(o => o.checked).forEach(o => o.checked = false);

      this.formData = this.formBuilder.group({
        message: null,
      });
      this.modalService.dismissAll();
      this.appContext.getConversations(null);
      this.spinner.hide();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

}
