import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormService} from "../../core/services/form.service";
import {FormModel} from "../../core/models/form.model";
import {NotifierService} from "angular-notifier-3";

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})


export class FormComponent implements OnInit {

  forms: FormModel[] = [];
  selectedForm: FormModel;

  constructor(private modalService: NgbModal,
              private notifierService: NotifierService,
              private formService: FormService) {
  }

  loadForms() {
    this.formService.getForms().subscribe(data => {
      console.log(data);
      this.forms = data;
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  editForm(form: FormModel, content: any) {
    this.selectedForm = form;
    this.openModal(content);
  }

  createForm(content: any) {
    this.selectedForm = new FormModel();
    this.openModal(content);
  }

  ngOnInit(): void {
    this.loadForms();
  }

  openModal(content: any) {
    this.modalService.open(content, {centered: true, size: 'xl'});
  }

  add() {
    this.selectedForm.values.push({
      name: '',
      type: '',
      key: '',
      required: true,
      inputType: '',
      initialValue: '',
      allowedValues: []
    });
  }

  addValue(index: number) {
    const res = prompt("Podaj nazwe");
    this.selectedForm.values[index].allowedValues.push(res);
  }

  editValue(index: number, index2: number) {
    const item = this.selectedForm.values[index].allowedValues[index2];
    this.selectedForm.values[index].allowedValues[index2] = prompt("Wprowadź nazwe", item);
  }

  removeValue(index: number, index2: number) {
    this.selectedForm.values[index].allowedValues.splice(index2, 1);
  }

  removeItem(index: number) {
    this.selectedForm.values.splice(index, 1);
  }

  deleteForm() {
    this.formService.delete(this.selectedForm.id).subscribe(data => {
      this.notifierService.notify(data.status, data.message)
      this.modalService.dismissAll();
      this.loadForms();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  save() {
    this.formService.create(this.selectedForm).subscribe(data => {
      this.notifierService.notify(data.status, data.message)
      this.modalService.dismissAll();
      this.loadForms();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

}
