import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from "../../../environments/environment.prod";
import {StatusMessageModel} from "../models/status.message.model";
import {OperatorModel} from "../models/operator.model";
import {CreateOperatorRequest} from "../models/create.operator.request";
import {UpdateGroupOperatorRequest} from "../models/update.group.operator.request";
import {ErrorService} from "./error.service";
import {catchError} from "rxjs/operators";
import {EditOperatorModel} from "../models/edit.operator.model";

@Injectable({providedIn: 'root'})
export class OperatorService {
  constructor(private http: HttpClient,
              private errorService: ErrorService) {
  }

  getOperators() {
    return this.http.get<OperatorModel[]>(`${environment.baseUrl}${environment.getOperators}`)
      .pipe(catchError(this.errorService.handleError));
  }

  createOperator(body: CreateOperatorRequest) {
    return this.http.post<StatusMessageModel>(`${environment.baseUrl}${environment.createOperator}`, body)
      .pipe(catchError(this.errorService.handleError));
  }
  editOperator(body: EditOperatorModel) {
    return this.http.patch<StatusMessageModel>(`${environment.baseUrl}${environment.editOperator}`, body)
      .pipe(catchError(this.errorService.handleError));
  }
  deleteOperator(id: number) {
    return this.http.delete<StatusMessageModel>(`${environment.baseUrl}${environment.deleteOperator}${id}`)
      .pipe(catchError(this.errorService.handleError));
  }

  updateGroupOperator(body: UpdateGroupOperatorRequest) {
    return this.http.patch<StatusMessageModel>(`${environment.baseUrl}${environment.updateOperatorGroup}`, body)
      .pipe(catchError(this.errorService.handleError));
  }

}
