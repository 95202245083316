import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import '@angular/common/locales/global/pl';

@Pipe({
  name: 'dateConversation'
})
export class DateConversation implements PipeTransform {
  transform(date: number) {
    const value = new Date(date);

    return (new DatePipe("pl-PL")).transform(value, 'MM-dd HH:mm');
  }
}
