import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from "../../../environments/environment.prod";
import {MessageModel} from "../models/message.model";
import {AddMessageModel} from "../models/add.message.model";
import {MarkSeenMessageModel} from "../models/mark.seen.message.model";
import {StatusMessageModel} from "../models/status.message.model";
import {AddGroupsMessageModel} from "../models/add.groups.message.model";
import {AssetModel} from "../models/asset.model";
import {ErrorService} from "./error.service";
import {catchError} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class MessageService {
  constructor(private http: HttpClient,
              private errorService: ErrorService) {
  }

  getMessages(id: number) {
    return this.http.get<MessageModel[]>(`${environment.baseUrl}${environment.messages}${id}`)
      .pipe(catchError(this.errorService.handleError));
  }

  addMessage(body: AddMessageModel) {
    return this.http.post<MessageModel>(`${environment.baseUrl}${environment.addMessage}`, body)
      .pipe(catchError(this.errorService.handleError));
  }

  addMultiMessage(body: AddGroupsMessageModel) {
    return this.http.post<MessageModel>(`${environment.baseUrl}${environment.addMultiMessage}`, body)
      .pipe(catchError(this.errorService.handleError));
  }

  markSeenMessage(body: MarkSeenMessageModel) {
    return this.http.patch<StatusMessageModel>(`${environment.baseUrl}${environment.markMessage}`, body)
      .pipe(catchError(this.errorService.handleError));
  }

  uploadAssets(body: FormData) {
    return this.http.post<AssetModel[]>(`${environment.assetBaseUrl}${environment.uploadAsset}`, body)
      .pipe(catchError(this.errorService.handleError));
  }

}
