import { Pipe, PipeTransform } from '@angular/core';
import '@angular/common/locales/global/pl';
import {Conversations} from "../models/conversation.model";

@Pipe({
  name: 'isReadConversationPipe'
})
export class IsReadConversationPipe implements PipeTransform {
  transform(conversation: Conversations, userId: number) {
    return !conversation.seensBy.includes(userId);
  }
}
