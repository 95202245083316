import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from "../../../environments/environment.prod";
import {StatusMessageModel} from "../models/status.message.model";
import {GroupModel} from "../models/group.model";
import {CreateGroupRequest} from "../models/create.group.request";
import {ErrorService} from "./error.service";
import {catchError} from "rxjs/operators";
import {EditGroupRequest} from "../models/edit.group.request";

@Injectable({providedIn: 'root'})
export class GroupService {
  constructor(private http: HttpClient,
              private errorService: ErrorService) {
  }

  getGroups() {
    return this.http.get<GroupModel[]>(`${environment.baseUrl}${environment.getGroups}`)
      .pipe(catchError(this.errorService.handleError));
  }
  getGroupById(id: number) {
    return this.http.get<GroupModel>(`${environment.baseUrl}${environment.getGroupById}${id}`)
      .pipe(catchError(this.errorService.handleError));
  }
  delete(id: number) {
    return this.http.delete<StatusMessageModel>(`${environment.baseUrl}${environment.getGroupById}${id}`)
      .pipe(catchError(this.errorService.handleError));
  }
  edit(group: EditGroupRequest) {
    return this.http.patch<StatusMessageModel>(`${environment.baseUrl}${environment.editGroup}`,group)
      .pipe(catchError(this.errorService.handleError));
  }

  createGroups(body: CreateGroupRequest) {
    return this.http.post<StatusMessageModel>(`${environment.baseUrl}${environment.createGroup}`, body)
      .pipe(catchError(this.errorService.handleError));
  }

}
