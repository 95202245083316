import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {TranslateService} from '@ngx-translate/core';
import {OperatorModel} from "../../../core/models/operator.model";
import {OperatorService} from "../../../core/services/operator.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CreateOperatorRequest} from "../../../core/models/create.operator.request";
import {AppContext} from "../../../core/services/app.context";
import {GroupModel} from "../../../core/models/group.model";
import {GroupService} from "../../../core/services/group.service";
import {ChangeGroupModel} from "../../../core/models/change.group.model";
import {UpdateGroupOperatorRequest} from "../../../core/models/update.group.operator.request";
import {NotifierService} from "angular-notifier-3";
import {EditOperatorModel} from "../../../core/models/edit.operator.model";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
/**
 * Tab-contacts component
 */
export class ContactsComponent implements OnInit {

  operators: OperatorModel[];
  groups: GroupModel[];
  operator: OperatorModel;
  formData!: FormGroup;
  groupChanges: ChangeGroupModel;

  constructor(private modalService: NgbModal,
              private formBuilder: FormBuilder,
              private appContext: AppContext,
              private groupService: GroupService,
              public translate: TranslateService,
              private notifierService: NotifierService,
              private operatorService: OperatorService) {
    this.formData = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: [''],
    });
  }

  getGroups() {
    this.groupService.getGroups().subscribe(data => {
      this.groups = data;
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  ngOnInit(): void {
    this.loadOperators();
    this.getGroups();
  }

  loadOperators() {
    this.operatorService.getOperators().subscribe(data => {
      this.operators = data;
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    })
  }

  createOperator() {
    const body: CreateOperatorRequest = {
      username: this.formData.get('username')!.value,
      password: this.formData.get('password')!.value,
      role: 'DRIVER',
      agreementId: 0
    }
    this.operatorService.createOperator(body).subscribe(data => {
      this.notifierService.notify(data.status, data.message);
      this.modalService.dismissAll();
      this.loadOperators();
      this.appContext.getConversations(null);
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }
  edit(operator: OperatorModel, content: any){
    this.operator = operator;
    this.formData.setValue({
      username: operator.username,
      password: ''
    })
    this.openContactsModal(content);
  }
  editOperator(){
    const body: EditOperatorModel = {
      id: this.operator.id,
      username: this.formData.get('username')!.value,
      password: this.formData.get('password')!.value,
    }
    this.operatorService.editOperator(body).subscribe(data => {
      this.notifierService.notify(data.status, data.message);
      this.modalService.dismissAll();
      this.loadOperators();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  delete(operator: OperatorModel) {
    this.operatorService.deleteOperator(operator.id).subscribe(data => {
      this.notifierService.notify(data.status, data.message);
      this.modalService.dismissAll();
      this.loadOperators();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  openContactsModal(content: any) {
    this.modalService.open(content, {centered: true});
  }

  changeGroupModal(operator: OperatorModel, content: any) {
    this.groupChanges = {
      operator: operator,
      selected: operator.group.id,
      groups: this.groups
    }
    this.groupChanges.groups.forEach(g => {
      if (g.id == this.groupChanges.selected) {
        g.checked = true
      }
    });

    this.modalService.open(content, {centered: true});
  }

  choose(group: GroupModel) {
    this.groupChanges.selected = group.id;
    this.groupChanges.groups.filter(g => g.id != group.id).filter(g => g.checked).forEach(g => {
      g.checked = false
    })
  }

  update() {
    const body: UpdateGroupOperatorRequest = {
      operatorId: this.groupChanges.operator.id,
      groupId: this.groupChanges.selected
    }
    this.operatorService.updateGroupOperator(body).subscribe(data => {
      this.groupChanges = null;
      this.loadOperators();
      this.modalService.dismissAll();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    })
  }

}
