import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GroupModel} from "../../core/models/group.model";
import {GroupService} from "../../core/services/group.service";
import {CreateGroupRequest} from "../../core/models/create.group.request";
import {OperatorModel} from "../../core/models/operator.model";
import {OperatorService} from "../../core/services/operator.service";
import {NotifierService} from "angular-notifier-3";
import {EditGroupRequest} from "../../core/models/edit.group.request";

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})

/**
 * Chat-component
 */
export class GroupComponent implements OnInit {

  searchQuery = '';
  groups: GroupModel[];
  formData!: FormGroup;
  operators: OperatorModel[] = [];
  group: GroupModel;

  constructor(private modalService: NgbModal,
              private notifierService: NotifierService,
              private formBuilder: FormBuilder,
              private operatorService: OperatorService,
              private groupService: GroupService) {
    this.formData = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
  }

  loadGroups() {
    this.groupService.getGroups().subscribe(data => {
      this.groups = data;
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  getGroup(group: GroupModel, content: any) {
    this.groupService.getGroupById(group.id).subscribe(data => {
      this.group = group;
      this.formData.setValue(
        {
          name: data.name
        }
      );
      this.openGroupModal(content);
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }
  deleteGroup(group: GroupModel) {
    this.groupService.delete(group.id).subscribe(data => {
     this.notifierService.notify(data.status, data.message);
     this.loadGroups();
     this.modalService.dismissAll();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  create(content: any) {
    this.formData.setValue(
      {
        name: ''
      });
    this.openGroupModal(content);
  }
  edit(){
    const body: EditGroupRequest = {
      id: this.group.id,
      name: this.formData.get('name')!.value
    };
    this.groupService.edit(body).subscribe(data => {
      this.notifierService.notify(data.status, data.message);
      this.loadGroups();
      this.modalService.dismissAll();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }


  createGroup() {
    this.clearSelected();
    const body: CreateGroupRequest = {
      name: this.formData.get('name')!.value,
    }
    this.groupService.createGroups(body).subscribe(data => {
      this.modalService.dismissAll();
      this.loadGroups();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  clearSelected() {
    this.operators.filter(o => o.checked).forEach(o => o.checked = false);
  }

  ngOnInit(): void {
    this.loadGroups();
  }


  openGroupModal(content: any) {
    this.modalService.open(content, {centered: true});
  }

}
