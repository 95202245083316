import {Pipe, PipeTransform} from '@angular/core';
import '@angular/common/locales/global/pl';
import {Conversations} from "../models/conversation.model";

@Pipe({
  name: 'searchConversationPipe'
})
export class SearchConversationPipe implements PipeTransform {
  transform(conversations:Conversations[], date: string) {
    if (conversations == null){
      return [];
    }
    return conversations.filter(g => g.displayName.includes(date) || g.lastMessage?.content.includes(date));
  }
}
