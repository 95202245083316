<div id="pills-groups" role="tabpanel" aria-labelledby="pills-groups-tab">
  <!-- Start Groups content -->
  <div>
    <div class="p-4">
      <div class="user-chat-nav float-end">
        <div ngbTooltip="{{ 'agreements.modal.add' | translate}}">
          <!-- Button trigger modal -->
          <button type="button" class="btn btn-link text-decoration-none text-muted font-size-18 py-0"
                  data-toggle="modal" data-target="#addgroup-exampleModal" (click)="create(content)">
            <i class="ri-group-line me-1 ms-0"></i>
          </button>
        </div>
      </div>
      <h4 class="mb-4">{{ 'agreements.title' | translate }}</h4>

      <!-- Start add group Modal -->
      <ng-template #content let-modal>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
              {{ 'agreements.title' | translate }}</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
            </button>
          </div>
          <div class="modal-body p-4">
            <div>
              <div class="mb-4">
                <label class="form-label" for="name-input">{{ 'agreements.modal.name' | translate }}</label>
                <input type="text" [(ngModel)]="selectedAgreement.name" class="form-control" id="name-input">
              </div>
              <div class="mb-4">
                <label class="form-label" for="email-input">{{ 'agreements.modal.email' | translate }}</label>
                <input type="text" [(ngModel)]="selectedAgreement.email" class="form-control" id="email-input">
              </div>
              <div class="mb-4" *ngIf="selectedAgreement.id != 0">
                <label class="form-label">{{ 'agreements.modal.operators' | translate }}</label>
                <div class="mb-3">
                  <button class="btn btn-light btn-sm" type="button"
                          (click)="selectedAgreement.newOperator = !selectedAgreement.newOperator">
                    {{ 'agreements.modal.add.shipper' | translate }}
                  </button>
                </div>
                <div *ngIf="selectedAgreement.id != 0 && selectedAgreement.newOperator">
                  <hr>
                  <div class="mb-4">
                    <label class="form-label" for="username-input">{{ 'agreements.modal.username' | translate }}</label>
                    <input type="text" [(ngModel)]="selectedAgreement.data.username" class="form-control"
                           id="username-input">
                  </div>
                  <div class="mb-4">
                    <label class="form-label" for="password-input">{{ 'agreements.modal.password' | translate }}</label>
                    <input type="text" [(ngModel)]="selectedAgreement.data.password" class="form-control"
                           id="password-input">
                  </div>
                  <button class="btn btn-light btn-sm" type="button" (click)="createShipper()">
                    {{ 'agreements.modal.save' | translate }}
                  </button>
                  <hr>
                </div>

                <div *ngIf="selectedAgreement.id != 0">
                  <div class="card border">
                    <div class="card-header">
                    </div>
                    <div class="card-body p-2">
                      <ngx-simplebar data-simplebar style="height: 150px;">
                        <div>

                          <ul class="list-unstyled contact-list">
                            @for (operator of selectedAgreement.operators; track $index) {
                              <li>
                                <div class="form-check">
                                  <label class="form-check-label"> {{ operator.username }}</label>
                                </div>
                              </li>
                            }
                          </ul>
                        </div>
                      </ngx-simplebar>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal" (click)="modal.dismiss('Cross click')">{{
                'agreements.button.cancel' |
                  translate
              }}
            </button>
            <button type="button" class="btn btn-primary" (click)="createAgreement()">
              {{ 'agreements.modal.save' | translate }}
            </button>
          </div>
        </div>
      </ng-template>
      <!-- End add group Modal -->

      <div class="search-box chat-search-box">
        <div class="input-group rounded-3">
          <button class="input-group-text text-muted bg-light pe-1 ps-3" type="button">
            <i class="ri-search-line search-icon font-size-18"></i>
          </button>
          <input type="text" class="form-control bg-light" placeholder="{{'agreements.search.placeholder' | translate}}"
                 [(ngModel)]="searchQuery" id="searchGroup" autocomplete="off">
        </div>
      </div>
      <!-- end search-box -->
    </div>

    <!-- Start chat-group-list -->
    <ngx-simplebar class="p-4 chat-message-list chat-group-list" data-simplebar>
      <ul class="list-unstyled chat-list">
        @for (item of agreements | searchAgreementPipe: searchQuery; track $index) {
          <li (click)="editAgreement(item, content)">
            <a href="javascript:void(0);">
              <div class="d-flex align-items-center">
                <div class="chat-user-img me-3 ms-0">
                  <div class="avatar-xs">
                        <span class="avatar-title rounded-circle bg-primary-subtle text-primary">
                          {{ (item.name | translate).charAt(0) }}
                        </span>
                  </div>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h5
                    class="text-truncate font-size-14 mb-0">{{ 'agreements.name' | translate }} {{ item.name | translate }}</h5>
                  <h6
                    class="text-truncate font-size-14 mb-0">{{ 'agreements.email' | translate }} {{ item.email | translate }}</h6>
                </div>
              </div>
            </a>
          </li>
        }
      </ul>
    </ngx-simplebar>
    <!-- End chat-group-list -->
  </div>
  <!-- End Groups content -->
</div>
