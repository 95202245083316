<!-- Start Contact content -->
<div>
  <div class="p-4">
    <div class="user-chat-nav float-end">
      <div ngbTooltip="{{ 'contacts.modal.add' | translate}}">
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-link text-decoration-none text-muted font-size-18 py-0" data-toggle="modal"
                data-target="#addContact-exampleModal" (click)="openContactsModal(content)">
          <i class="ri-add-circle-fill me-1 ms-0"></i>
        </button>
      </div>
    </div>
    <h4 class="mb-4">{{ 'contacts.title' | translate }}</h4>

    <!-- Start Add contact Modal -->
    <ng-template #content let-modal>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-size-16" id="addContact-exampleModalLabel">
            {{ 'contacts.modal.add' | translate }}</h5>
          <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')">
          </button>
        </div>
        <div class="modal-body p-4">
          <form [formGroup]="formData">
            <div class="mb-3">
              <label class="form-label" for="username-input">{{ 'contacts.form.username.label' | translate }}</label>
              <input type="email" class="form-control" id="username-input" formControlName="username"
                     placeholder="{{'contacts.form.username.label' | translate}}">
            </div>
            <div class="mb-3">
              <label class="form-label" for="password-input">{{ 'contacts.form.password.label' | translate }}</label>
              <input type="email" class="form-control" id="password-input" formControlName="password"
                     placeholder="{{'contacts.form.password.label' | translate}}">
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link"
                  (click)="modal.dismiss('Cross click')">{{ 'contacts.button.cancel' | translate }}
          </button>
          <button type="button" (click)="createOperator()"
                  class="btn btn-primary">{{ 'contacts.button.save' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
    <ng-template #editContent let-modal>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-size-16" >
            {{ 'contacts.modal.add' | translate }}</h5>
          <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')">
          </button>
        </div>
        <div class="modal-body p-4">
          <form [formGroup]="formData">
            <div class="mb-3">
              <label class="form-label" for="username">{{ 'contacts.form.username.label' | translate }}</label>
              <input type="email" class="form-control" id="username" formControlName="username"
                     placeholder="{{'contacts.form.username.label' | translate}}">
            </div>
            <div class="mb-3">
              <label class="form-label" for="password">{{ 'contacts.form.password.label' | translate }}</label>
              <input type="email" class="form-control" id="password" formControlName="password"
                     placeholder="{{'contacts.form.password.label' | translate}}">
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link"
                  (click)="modal.dismiss('Cross click')">{{ 'contacts.button.cancel' | translate }}
          </button>
          <button type="button" (click)="editOperator()"
                  class="btn btn-primary">{{ 'contacts.button.save' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
    <!-- End Add contact Modal -->

    <div class="search-box chat-search-box">
      <div class="input-group bg-light input-group-lg rounded-3">
        <div class="input-group-prepend">
          <button class="btn btn-link text-decoration-none text-muted pe-1 ps-3" type="button">
            <i class="ri-search-line search-icon font-size-18"></i>
          </button>
        </div>
        <input type="text" class="form-control bg-light"
               placeholder="{{'contacts.search.placeholder' | translate}}">
      </div>
    </div>
    <!-- End search-box -->
  </div>
  <!-- end p-4 -->

  <!-- Start contact lists -->
  <ngx-simplebar class="p-4 chat-message-list chat-group-list" data-simplebar>
    @for (item of operators; track $index) {
      <div>
        <ul class="list-unstyled contact-list">
          <li>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <h5 class="font-size-14 m-0">{{ item.username | translate }}</h5>
                <small> {{ item.group?.name }}</small>
              </div>

              <div class="dropdown" ngbDropdown>
                <a href="javascript: void(0);" class="text-muted dropdown-toggle" ngbDropdownToggle
                   data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                  <a class="dropdown-item" (click)="changeGroupModal(item, changeGroup)">
                    {{ 'contacts.button.change_group' | translate }}
                  </a>
                  <a class="dropdown-item" (click)="edit(item, editContent)">
                    {{ 'contacts.button.edit_user' | translate }}
                  </a>
                  <a class="dropdown-item" (click)="delete(item)">
                  {{ 'contacts.button.delete_user' | translate }}
                </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    }
    <!-- end contact list A -->
    <ng-template #changeGroup let-modal>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-size-16">
            {{ 'contacts.button.change_group' | translate }}</h5>
          <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')">
          </button>
        </div>
        <div class="modal-body p-4">
          <div>
            <div class="mb-3">
              <label class="form-label" for="username-input">{{ 'contacts.form.username.label' | translate }}</label>
              <input type="email" class="form-control" [(ngModel)]="groupChanges.operator.username" readonly
                     placeholder="{{'contacts.form.username.label' | translate}}">
            </div>
            <div class="mb-4">
              <label class="form-label">{{ 'contacts.group' | translate }}</label>

              <div>
                <div class="card border">
                  <div class="card-header">
                    <h5 class="font-size-15 mb-0">{{ 'contacts.group' | translate }}</h5>
                  </div>
                  <div class="card-body p-2">
                    <ngx-simplebar data-simplebar style="height: 150px;">
                      <div>

                        <ul class="list-unstyled contact-list">
                          @for (group of groupChanges.groups; track $index) {
                            <li>
                              <div class="form-check">
                                <input type="checkbox" class="form-check-input" [(ngModel)]="group.checked"
                                       [ngModelOptions]="{'standalone': true}" (ngModelChange)="choose(group)">
                                <label class="form-check-label"> {{ group.name }}</label>
                              </div>
                            </li>
                          }
                        </ul>
                      </div>
                    </ngx-simplebar>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link"
                  (click)="modal.dismiss('Cross click')">{{ 'contacts.button.cancel' | translate }}
          </button>
          <button type="button" (click)="update()"
                  class="btn btn-primary">{{ 'contacts.button.save'| translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </ngx-simplebar>
  <!-- end contact lists -->
</div>
<!-- Start Contact content -->
