import {Pipe, PipeTransform} from '@angular/core';
import '@angular/common/locales/global/pl';

@Pipe({
  name: 'parseFormMessage'
})
export class ParseFormMessage implements PipeTransform {

  transform(data: string) {
    return JSON.parse(data);
  }
}
