import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import '@angular/common/locales/global/pl';
import {AuthService} from "../services/auth.service";
import {SeenModel} from "../models/seen.model";

@Pipe({
  name: 'readDateMessagePipe'
})
export class ReadDateMessagePipe implements PipeTransform {
  constructor(private authService: AuthService) {
  }

  transform(data: SeenModel[]) {
    return data
      .filter(s => s.operatorId != this.authService.currentUserValue.id)
      .map(d =>
        (new DatePipe("pl-PL")).transform(new Date(d.createdAt), 'MM-dd HH:mm')
      );
  }
}
