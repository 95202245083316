import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import '@angular/common/locales/global/pl';

@Pipe({
  name: 'formatDateConversation'
})
export class FormatDateConversation implements PipeTransform {
  transform(date: number) {
    const value = new Date(date);
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    if (value.getFullYear() == today.getFullYear() && value.getMonth() == today.getMonth() && value.getDate() == today.getDate())
      return "Dzisiaj";
    else if (value.getFullYear() == yesterday.getFullYear() && value.getMonth() == yesterday.getMonth() && value.getDate() == yesterday.getDate())
      return "Wczoraj";
    else {
      return (new DatePipe("pl-PL")).transform(value, 'MM-dd HH:mm');
    }
  }
}
