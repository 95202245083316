import {
  AfterViewInit,
  Component,
  ElementRef, HostListener,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import {AuthService} from "../../core/services/auth.service";
import {Conversations} from "../../core/models/conversation.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "../../core/services/message.service";
import {MessageModel} from "../../core/models/message.model";
import {AddMessageModel} from "../../core/models/add.message.model";
import {AppContext} from "../../core/services/app.context";
import {MarkSeenMessageModel} from "../../core/models/mark.seen.message.model";
import {NotifierService} from "angular-notifier-3";
import {fromEvent, tap} from "rxjs";

@Component({
  selector: 'app-conversation-detail',
  templateUrl: './conversation.detail.html',
  styleUrls: ['./conversation.detail.scss']
})

/**
 * Chat-component
 */
export class ConversationDetail implements OnChanges, AfterViewInit {
  @Input()
  conversation: Conversations;
  @ViewChild('scrollRef', { static: false }) scrollDiv;
  emoji = '';
  formData!: FormGroup;
  message: MessageModel[] = [];
  showEmojiPicker = false;
  img: any;
  meId: number = 0;
  request = {
    pcsPerPage: 20,
    page: 1
  }

  constructor(private messageService: MessageService,
              private formBuilder: FormBuilder,
              private notifierService: NotifierService,
              private appContext: AppContext,
              private authService: AuthService) {
    this.meId = this.authService.currentUserValue.id;
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });
    this.appContext.refreshMessages.subscribe(data => {
      this.getMessages();
    })
    fromEvent(document, 'scroll')
      .pipe(
        tap(() => {
          let fullDocumentHeight = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
          );
          const haveIReachedBottom =
            fullDocumentHeight ===
            window.scrollY + document.documentElement.clientHeight;
          if (haveIReachedBottom) {
           // this.request.page++;
           // this.getMessages();
          }
        })
      )
      .subscribe();
  }
  @HostListener('window:scroll', ['$event'])
  onWindowsScroll(event: Event) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop <= 50) {
      this.request.page++;
      this.getMessages();
    }
  }
  ngAfterViewInit(): void {
    this.onListScroll();
    }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.conversation != null) {
      this.getMessages();
    }
  }

  getSize(data) {
    const json = JSON.parse(data);
    return `${json.fileSize.toFixed(2)} ${json.sizeUnit}`;
  }


  buildURLQuery() {
    return Object.entries(this.request)
      .map(pair => pair.map(encodeURIComponent).join('='))
      .join('&');
  }
  private getMessages() {
    this.messageService.getMessages(this.conversation.id).subscribe(async data => {
      this.message = data.sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      });
        this.onListScroll();

      await this.markSeen();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  sendMarkSeen(m: MessageModel) {
    const body: MarkSeenMessageModel = {
      conversationId: this.conversation.id,
      messageId: m.id,
      date: new Date().getDate()
    }
    return new Promise((resolve, reject) => {
      this.messageService.markSeenMessage(body).subscribe(data => {
        console.log(data);
        resolve('');
      }, error => {
        this.notifierService.notify(error.error.status, error.error.message);
      });
    })

  }

  async markSeen() {
    // const userId = this.authService.currentUserValue.id;
    // for (let m of this.message) {
    //   const seen = m.seensBy.filter(s => s.operatorId == userId);
    //   if (seen.length == 0) {
    //     await this.sendMarkSeen(m);
    //   }
    // }
    //
    // this.appContext.getConversations(this.conversation.groupId);

  }

  closeUserChat() {
    document.getElementById('chat-room').classList.remove('user-chat-show');
  }

  onListScroll() {
    setTimeout(() => {
      const newScrollHeight = this.scrollDiv.nativeElement.scrollHeight;
      this.scrollDiv.nativeElement.scrollTo(0, newScrollHeight);
    }, 500);
  }

  CloseChatInfo() {
    (document.querySelector('.user-profile-sidebar') as HTMLElement).style.display = 'none'
  }

  messageSave() {
    const message: string = this.formData.get('message')!.value;
    if (message) {
      this.send(message, 'text');
    }
  }

  send(message: string, type: string, data: string = '') {
    const body: AddMessageModel = {
      conversationId: this.conversation.id,
      content: message,
      data: data,
      type: type,
      coords: {latitude: 0, longitude: 0}
    }
    this.messageService.addMessage(body).subscribe(data => {
      this.message.push(data);
      this.onListScroll();

      this.formData = this.formBuilder.group({
        message: null,
      });
      this.emoji = '';
      this.appContext.getConversations(null);
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event: any) {
    const {emoji} = this;
    this.emoji = `${emoji}${event.emoji.native}`;
    this.showEmojiPicker = false;
  }

  onFocus() {
    this.showEmojiPicker = false;
  }

  onBlur() {
  }

  selectFiles(event: any): void {
    const selectedFiles = event.target.files;
    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append(`files`, selectedFiles[i], selectedFiles[i].name);
    }
    this.messageService.uploadAssets(formData).subscribe(data => {
      data.forEach(asset => {
        this.send(asset.url, asset.format == "pdf" ? "file" : "photo",
          JSON.stringify({
            "fileName": asset.filename,
            "format": asset.format, "fileSize": asset.size, "sizeUnit": asset.unit
          }))
      })
      this.getMessages();
    }, error => {
    })

  }

}
