import { Pipe, PipeTransform } from '@angular/core';
import '@angular/common/locales/global/pl';

@Pipe({
  standalone: true,
  name: 'parsePhotoMessage'
})
export class ParsePhotoMessage implements PipeTransform {
  transform(data: string) {
    const json = JSON.parse(data);
    let result = "";
    for (const key in json) {
      const obj = json[key];
      result += `<b>${obj.name}</b></br> ${obj.value}</br>`;
    }
   return result;
  }
}
