import {HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class ErrorService {
  public handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error);
  }
}
