import {Component,OnInit} from '@angular/core';

import {AppContext} from "../../core/services/app.context";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {OperatorService} from "../../core/services/operator.service";
import {AgreementsModel} from "../../core/models/agreements.model";
import {AgreementService} from "../../core/services/agreement.service";
import {CreateOperatorRequest} from "../../core/models/create.operator.request";
import {NotifierService} from "angular-notifier-3";

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})

/**
 * Chat-component
 */
export class AgreementComponent implements OnInit {

  searchQuery = '';
  agreements: AgreementsModel[];
  selectedAgreement: AgreementsModel;

  constructor(private modalService: NgbModal,
              private notifierService: NotifierService,
              private appContext: AppContext,
              private operatorService: OperatorService,
              private agreementService: AgreementService,) {
  }

  loadAgreements() {
    this.agreementService.getAgreements().subscribe(data => {
      this.agreements = data;
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  editAgreement(agreement: AgreementsModel, content: any) {
    this.selectedAgreement = agreement;
    this.selectedAgreement.data = new CreateOperatorRequest();
    this.openModal(content);
  }

  createAgreement() {
    this.agreementService.createAgreement(this.selectedAgreement).subscribe(data => {
      this.modalService.dismissAll();
      this.loadAgreements();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  create(content:any) {
    this.selectedAgreement = new AgreementsModel(0);
    this.openModal(content);
  }

  ngOnInit(): void {
    this.loadAgreements();
  }


  openModal(content: any) {
    this.modalService.open(content, {centered: true});
  }

  createShipper(){
    const body: CreateOperatorRequest = {
      username: this.selectedAgreement.data.username,
      password: this.selectedAgreement.data.password,
      role: 'SHIPPER',
      agreementId: this.selectedAgreement.id
    }
    this.operatorService.createOperator(body).subscribe(data => {
      this.loadAgreements();
      this.appContext.getConversations(null);
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

}
