import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule, NgbDropdownModule, NgbAccordionModule, NgbModalModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { LightboxModule } from 'ngx-lightbox';

import { TabsModule } from './tabs/tabs.module';

import { ChatRoutingModule } from './chat-routing.module';

import {DatePipe} from '@angular/common';

// Emoji Picker
import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { CarouselModule } from 'ngx-owl-carousel-o';

// Simplebar
import { SimplebarAngularModule } from 'simplebar-angular';

import { IndexComponent } from './index/index.component';
import { TranslateModule } from '@ngx-translate/core';
import {ConversationComponent} from "./conversations/conversation.component";
import {FormatDateConversation} from "../core/pipe/format.date.conversation";
import {LastMessageConversationPipe} from "../core/pipe/last.message.conversation.pipe";
import {IsReadConversationPipe} from "../core/pipe/is.read.conversation.pipe";
import {ConversationDetail} from "./detail/conversation.detail";
import {DateConversation} from "../core/pipe/date.conversation";
import {ParseFormMessage} from "../core/pipe/parse.form.message";
import {SecurePipe} from "../core/pipe/secure.pipe";
import {GroupComponent} from "./groups/group.component";
import {SearchGroupPipe} from "../core/pipe/search.group.pipe";
import {SearchConversationPipe} from "../core/pipe/search.conversation.pipe";
import {ReadDateMessagePipe} from "../core/pipe/read.date.message.pipe";
import {FormComponent} from "./form/form.component";
import {AgreementComponent} from "./agreement/agreement.component";
import {SearchAgreementPipe} from "../core/pipe/search.agreement.pipe";
import {NgxPermissionsModule} from "ngx-permissions";

@NgModule({
  declarations: [IndexComponent, ConversationComponent,
    FormatDateConversation, LastMessageConversationPipe, IsReadConversationPipe,
    ConversationDetail, DateConversation, ParseFormMessage, SecurePipe,
    GroupComponent, SearchGroupPipe, SearchConversationPipe, ReadDateMessagePipe, FormComponent, AgreementComponent, SearchAgreementPipe],
  imports: [
    LightboxModule,
    NgbAccordionModule,
    NgbModalModule,
    NgbCollapseModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChatRoutingModule,
    TabsModule,
    NgbTooltipModule,
    NgbDropdownModule,
    TranslateModule,
    SimplebarAngularModule,
    PickerModule,
    CarouselModule,
    NgxPermissionsModule
  ],
  providers: [
    DatePipe
  ],
  exports: []
})
export class ChatModule { }
