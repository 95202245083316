import {OperatorModel} from "./operator.model";
import {CreateOperatorRequest} from "./create.operator.request";

export class AgreementsModel {
  id: number;
  name: string;
  email: string;
  operators: OperatorModel[];
  newOperator: boolean;
  data: CreateOperatorRequest;


  constructor(id: number) {
    this.id = id;
  }
}
