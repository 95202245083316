<div class="user-chat w-100 h-100 overflow-hidden d-none" id="chat-room">
  <div class="d-lg-flex">
    <!-- start chat conversation section -->
    <div class="w-100 overflow-hidden position-relative">
      <div class="p-3 p-lg-4 border-bottom user-chat-topbar">
        <div class="row align-items-center">
          <div class="col-sm-4 col-8">
            <div class="d-flex align-items-center">
              <div class="d-block d-lg-none me-2 ms-0">
                <a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-2"
                   (click)="closeUserChat()"><i class="ri-arrow-left-s-line"></i></a>
              </div>
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-16 mb-0 text-truncate">
                  <a href="javascript:void(0);"
                     class="text-reset user-profile-show username">{{ conversation?.displayName }}</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end chat user head -->
      <div id="users-chat" class="position-relative">
        <!--       start chat conversation -->
        <div style="overflow: auto" class="chat-conversation p-3 p-lg-4" #scrollRef>
          <ul class="list-unstyled mb-0" id="users-conversation">
            @for (data of message; track $index) {
              <li class="chats" [ngClass]="{'right': data.operatorId == meId}">
                <div class="conversation-list">


                  <div class="user-chat-content">
                    <div class="ctext-wrap">
                      <div class="ctext-wrap-content">
                        @if (data.type === 'form') {
                          <p class="mb-0 messageText">
                          <ul class="list-inline" style="text-align: left;color:black">
                            <li *ngFor="let obj of data.data | parseFormMessage">
                              <b> {{ obj.name }}</b><br>
                              @if (obj.type == 'photo') {
<!--                                <img [src]="obj.value | secure | async" class="rounded border">-->
                              } @else {
                                @if (obj.value == null) {
                                  Brak
                                } @else {
                                  {{ obj.value }}
                                }
                              }
                            </li>
                          </ul>
                        } @else if (data.type == 'photo') {
                          <ul class="list-inline message-img mb-0">
                            <li class="list-inline-item message-img-list">
                              <div>
                                <a class="popup-img d-inline-block m-1 p-0" href="javascript:void(0);"
                                   title="Project 1">
                                  <img [src]=" data.content | secure | async" class="rounded border">

                                </a>
                              </div>
                            </li>
                            <li>
                              <small style="color:black;">{{  'conversations.size' | translate }}: {{ getSize(data.data)}}</small>
                            </li>
                          </ul>
                        } @else if (data.type == 'file') {
                          <ul class="list-inline message-img mb-0">
                            <li class="list-inline-item message-img-list">
                              <div>
                                <a class="popup-img d-inline-block m-1 p-0" href="javascript:void(0);"
                                 >
                                  <img src="assets/images/pdf.png" class="rounded border" style="width: 50px;">
                                  {{  'conversations.download' | translate }}
                                </a>
                              </div>
                            </li>
                            <li>
                              <small>{{ 'conversations.size' }}: {{ getSize(data.data)}}</small>
                            </li>
                          </ul>
                        } @else {

                          <p class="mb-0 messageText" style="color:black" [innerHTML]="data.content">
                          </p>
                        }
                        @let seen = data.seensBy | readDateMessagePipe ;
                        @let delivery = data.seensBy | readDateMessagePipe ;

                        <div style="color:gray">
                          @if (seen.length > 0 || delivery.length > 0){
                          <hr>
                          }
                          <ul style="list-style-type: none;">
                            @if (delivery.length > 0) {
                              <li><small>{{  'conversations.delivery' | translate }} {{ delivery }}</small></li>
                            }
                            @if (seen.length > 0) {
                              <li><small>{{  'conversations.seen' | translate }} {{ seen }}</small></li>
                            }
                          </ul>
                        </div>
                      </div>


                    </div>

                    <div class="conversation-name">
                      <span> {{ data.username }}</span>
                      <small> {{ data.createdAt | dateConversation }}</small>
                    </div>

                  </div>
                </div>

              </li>
            }
          </ul>
        </div>
        <div class="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade" id="copyClipBoard"
             role="alert">
          Message copied
        </div>
        <!-- </div> -->
        <!-- end chat conversation end -->

        <!-- start chat input section -->
        <div class="chat-input-section p-3 p-lg-4 border-top mb-0 send-msg">
          <form (ngSubmit)="messageSave()" [formGroup]="formData" id="chatinput-form" enctype="multipart/form-data">
            <div class="row g-0">
              <div class="col">
                <div>
                  <input type="text" class="form-control form-control-lg bg-light border-light"
                         placeholder="{{  'conversations.type.message' | translate }}" formControlName="message" (blur)="onBlur()"
                         (focus)="onFocus()">
                </div>
              </div>
              <div class="col-auto">
                <div class="chat-input-links ms-md-2 me-md-0">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <button type="button" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                              data-toggle="tooltip" data-placement="top" ngbTooltip="Emoji"
                              (click)="toggleEmojiPicker()" id="emoji-btn">
                        <i class="ri-emotion-happy-line"></i>
                      </button>
                      @if (showEmojiPicker) {
                        <emoji-mart class="emoji-mart" (emojiSelect)="addEmoji($event)"
                                    title="Pick your emoji…"></emoji-mart>
                      }
                    </li>
                    <li class="list-inline-item">
                      <label id="photos" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                        <i class="ri-image-line"></i>
                        <input name="fileInput" size="60" type="file" multiple class="form-control-file d-none"
                               (change)="selectFiles($event)">
                      </label>
                      <label id="files" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                        <i class="ri-attachment-line"></i>
                        <input name="fileInput" size="60" type="file"  multiple class="form-control-file d-none"
                               (change)="selectFiles($event)">
                      </label>
                    </li>
                    <li class="list-inline-item">
                      <button type="submit"
                              class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light">
                        <i class="ri-send-plane-2-fill"></i>
                      </button>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </form>
        </div>
        <!-- end chat input section -->

      </div>
      <!-- end chat conversation section -->

      <!-- Chat Info Sidebar -->
      <div class="user-profile-sidebar">
        <div class="px-3 px-lg-4 pt-3 pt-lg-4">
          <div class="user-chat-nav text-end">
            <button type="button" class="btn nav-btn" id="user-profile-hide" (click)="CloseChatInfo()">
              <i class="ri-close-line"></i>
            </button>
          </div>
        </div>
        <div class="text-center p-4 border-bottom">
          <h5 class="font-size-16 mb-1 text-truncate" *ngIf="conversation">{{ conversation.displayName }}</h5>
        </div>
        <!-- End profile user -->

      </div><!--end offcanvas-body-->
    </div>
  </div>
</div>
