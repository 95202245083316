import {Pipe, PipeTransform} from '@angular/core';
import '@angular/common/locales/global/pl';
import {Conversations} from "../models/conversation.model";

@Pipe({
  name: 'lastMessageConversationPipe'
})
export class LastMessageConversationPipe implements PipeTransform {
  transform(chat: Conversations) {
    if (chat.lastMessage == null) {
      return "Brak wiadomości";
    }
    switch (chat.lastMessage.type) {
      case "form":
        return "Zmiana statusu";
      case "photo":
        return "Zdjęcie";
      case "file":
        return "Załącznik";
      case "text":
        return chat.lastMessage.content;
    }
  }
}
