import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {environment} from "../../../environments/environment.prod";
import {StatusMessageModel} from "../models/status.message.model";
import {AgreementsModel} from "../models/agreements.model";
import {ErrorService} from "./error.service";
import {catchError} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class AgreementService {
    constructor(private http: HttpClient,
                private errorService: ErrorService) { }

    getAgreements() {
        return this.http.get<AgreementsModel[]>(`${environment.baseUrl}${environment.getAgreements}`)
          .pipe(catchError(this.errorService.handleError));
    }

    createAgreement(body: AgreementsModel){
      return this.http.post<StatusMessageModel>(`${environment.baseUrl}${environment.createAgreement}`, body)
        .pipe(catchError(this.errorService.handleError));
    }

}
