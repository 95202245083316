<div>

  <div class="px-4 pt-4">
    <h4 class="mb-4">{{'conversations.title' | translate}}</h4>

    <button class="btn btn-info mb-4" style="background-color: black"
    (click)="openModal(sendToMany)">{{'conversations.send.to.many' | translate}}</button>
    <div class="search-box chat-search-box">
      <div class="input-group mb-3 rounded-3">
        <select class="form-control bg-light" [(ngModel)]="selectedGroup" (ngModelChange)="setGroup()" >
          @for (x of groups;track $index){
            <option [ngValue]="x">{{'conversations.group' | translate}}: {{ x.name }}</option>
          }
        </select>
      </div>
    </div>
    <div class="search-box chat-search-box">
      <div class="input-group mb-3 rounded-3">
                <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
                  <i class="ri-search-line search-icon font-size-18"></i>
                </span>
        <input type="text" [(ngModel)]="searchQuery" class="form-control bg-light" placeholder="{{'conversations.search.placeholder' | translate}}"  aria-describedby="basic-addon1" id="searchContact" autocomplete="off">
      </div>
    </div>
  </div> <!-- .p-4 -->

  <h5 class="mb-3 px-3 font-size-16">{{'conversations.title' | translate}}</h5>

  <ngx-simplebar class="chat-message-list px-2" data-simplebar-track="light">
    <ul class="list-unstyled chat-list chat-user-list">
      @for(chat of conversations | searchConversationPipe: searchQuery ;track $index){
        <li (click)="showChat($event,chat)">
          <a href="javascript:void(0);">
            <div class="d-flex">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="text-truncate font-size-15 mb-1">{{chat.displayName | translate}}</h5>
                <p class="chat-user-message text-truncate mb-0">{{chat | lastMessageConversationPipe}}</p>
              </div>
              <div class="font-size-11">{{chat.lastMessage?.createdAt | formatDateConversation}}</div>
              <div class="unread-message">
                @if (chat | isReadConversationPipe: userId){
                <span class="badge badge-soft-danger rounded-pill">new</span>
                }
              </div>
            </div>
          </a>
        </li>
      }
    </ul>
  </ngx-simplebar>

</div>
<ng-template #sendToMany let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
        {{  'conversations.send.to.many' | translate }}</h5>
      <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      </button>
    </div>
    <div class="modal-body p-4">
      <form [formGroup]="formData">
        <div class="mb-4">
          <label class="form-label" for="content">{{ 'conversations.message.to.many.group' | translate}}</label>
          <textarea type="text" formControlName="message" class="form-control" id="content">
          </textarea>
        </div>
        <div class="mb-4">
          <label class="form-label">{{ 'conversations.groups' | translate}}</label>
          <div>
            <div class="card border">
              <div class="card-header">
                <h5 class="font-size-15 mb-0">{{ 'conversations.groups' | translate}}</h5>
              </div>
              <div class="card-body p-2">
                <ngx-simplebar data-simplebar style="height: 150px;">
                  <div>

                    <ul class="list-unstyled contact-list">
                      @for ( group of groups; track $index){
                        <li>
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input"  [(ngModel)]="group.checked"
                                   [ngModelOptions]="{'standalone': true}">
                            <label class="form-check-label" > {{ group.name}}</label>
                          </div>
                        </li>
                      }
                    </ul>
                  </div>
                </ngx-simplebar>
              </div>

            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-link" data-dismiss="modal" (click)="modal.dismiss('Cross click')">{{'conversations.button.cancel' |
        translate}}</button>
      <button type="button" class="btn btn-primary" (click)="send()" >{{'conversations.button.create' |
        translate}}</button>
    </div>
  </div>
</ng-template>
