import {Pipe, PipeTransform} from '@angular/core';
import '@angular/common/locales/global/pl';
import {AgreementsModel} from "../models/agreements.model";

@Pipe({
  name: 'searchAgreementPipe'
})
export class SearchAgreementPipe implements PipeTransform {
  transform(groups:AgreementsModel[], date: string) {
    return groups.filter(g => g.name.includes(date));
  }
}
