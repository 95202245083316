import {EventEmitter, Injectable} from "@angular/core";
import {Conversations} from "../models/conversation.model";
import {ConversationsService} from "./conversations.service";
import {GroupModel} from "../models/group.model";

@Injectable({providedIn: 'root'})
export class AppContext {
  conversations = new EventEmitter<Conversations[]>();
  refreshMessages = new EventEmitter<String>();
  selectedGroupEmitter = new EventEmitter<GroupModel>();
  selectedGroup: GroupModel;

  constructor(private conversationService: ConversationsService) {
    this.selectedGroupEmitter.subscribe(data => {
      if (data)
        this.getConversations(data.id);
    })
  }

  getConversations(groupId: number | null) {
    this.conversationService.getConversations(groupId == null ? this.selectedGroup.id : groupId).subscribe(data => {
      this.conversations.next(data);
    })
  }

  setGroup(value: GroupModel) {
    this.selectedGroup = value;
    this.selectedGroupEmitter.next(value)
  }
}
